.nav-container {
  display: flex;
  color: #264653;
  flex-direction: row;
  justify-content: space-between;
  background-color: whitesmoke;
  padding: 10px 5px 10px 3px;
  overflow-x: hidden;
  align-items: center;
  align-content: center;
  box-shadow: rgb(50 50 93 / 20%) 0px 30px 60px -12px,
    rgb(0 0 0 / 28%) 0px 18px 36px -18px;
  padding: 15px;
  min-width: 100vw;
  font-family: "Syne", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.logo-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 45%;
  align-items: flex-start;
}

#logo {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  margin: 0.5px 5px;
}

.descriptor-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
}

#name {
  font-size: 25px;
}

#occupation {
  opacity: 55%;
  font-size: 18px;
  line-height: 10px;
}

.nav-links-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-basis: 100%;
}

.dropbtn {
  display: none;
}

.link {
  display: flex;
  justify-content: space-evenly;
  color: #264653;
  border-radius: 25px;
  padding: 5px 15px;
  align-items: center;
  text-decoration: none;
  font-weight: 100;
  transition: background-color 0.2s ease, color 0.2s ease;
  margin: 0 10px;
  flex: 1;
  width: fit-content;
}

.link:hover {
  background-color: #264653;
  color: white;
  font-weight: 200;
}

/* Responsive Design */

@media (min-width: 1200px) {


  .logo-container {
    flex-basis: auto;
  }

  .nav-links-container {
    margin-right: 2vw;
    flex-basis: auto;
  }

  .link {
    width: auto;
    height: 3vh;
  }
}

@media (max-width: 1199px) and (min-width: 992px) {
  /* .nav-container {
    width: 100vw;
  } */

  .logo-container {
    flex-basis: auto;
  }

  .nav-links-container {
    width: 40vw;
    flex-basis: auto;
  }

  .link {
    width: auto;
    height: 3vh;
  }
}

@media (max-width: 991px) and (min-width: 785px) {
  .logo-container {
    flex-basis: auto;
    justify-content: flex-start;
  }

  .nav-links-container {
    flex-basis: auto;
  }

  .link {
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 3vh;
  }
}

@media (max-width: 784px) {
  .nav-container {
    height: auto;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: inherit;
    padding: 10px 5px;
    /* width: 100%; */
  }

  .logo-container {
    width: fit-content;
    flex-basis: auto;
    align-items: flex-start;
    color: rgb(0, 0, 0);
    margin-left: 5px;
  }

  .nav-links-container {
    display: none;
  }

  .link {
    height: auto;
    margin-top: 5px;
    font-size: 1rem;
    font-weight: lighter;
    flex-direction: row;
  }

  /* The dropdown container */
  .dropdown {
    display: contents;
    float: left;
    overflow: hidden;
  }

  /* Dropdown button */
  .dropdown .dropbtn {
    font-size: 16px;
    border: none;
    outline: none;
    color: inherit;
    background-color: inherit;
    font-family: inherit; /* Important for vertical align on mobile phones */
    margin: 0; /* Important for vertical align on mobile phones */
  }

  .dropbtn {
    display: block;
    z-index: 1;
  }

  /* Dropdown content (hidden by default) */
  .nav-links-container-open {
    display: flex;
    flex-direction: column;
    height: fit-content;
    height: 20%;
    width: 100%;
    position: absolute;
    align-items: center;
    z-index: 0;
    top: 0;
    right: 0;
    margin-top: 4.3vh;
    text-decoration: underline;
    /* margin-right: 3vw; */
    background-color: whitesmoke;
    /* #f9f9f9; */
    overflow-x: hidden;
    padding-bottom: 5px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
  }

  /* Show the dropdown menu on hover */
  /* .dropdown:hover .dropdown-content {
    display: block;
  } */

  #logo {
    width: 35px;
    height: 35px;
  }

  #name {
    font-size: 15px;
  }
  #occupation {
    font-size: 10px;
  }

  #nav-icon {
    font-size: 25px;
    opacity: 70%;
  }
}
