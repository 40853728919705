/* @import url(https://fonts.googleapis.com/css?family=Quicksand:300,400,700); */

body {
  margin: 0;
  padding: 0; /* Adjusted to a single padding setting */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100vw;
  width: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;
  align-items: center;
  align-content: center;
  align-self: center;
  background: linear-gradient(
    to right,
    rgba(92, 129, 202, 1) 0%,
    rgba(249, 140, 112, 1) 100%
  );
  background-size: 175% 175%;
  -webkit-animation: bg-animation 20s ease-in-out infinite;
  -moz-animation: bg-animation 20s ease-in-out infinite;
  animation: bg-animation 20s ease-in-out infinite;
}

@-webkit-keyframes bg-animation {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}
@-moz-keyframes bg-animation {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}
@keyframes bg-animation {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}

/* Responsive Design */

@media (min-width: 1200px) {
}

@media (max-width: 1199px) and (min-width: 992px) {
}

@media (max-width: 991px) and (min-width: 768px) {
}

@media (max-width: 767px) {
}
