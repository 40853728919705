.services-page {
  height: fit-content;
  background-color: #faf3e9;
  color: #264653;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px 50px 80px 50px;
  margin: 5vh 2vw;
  border-radius: 15px;
}

.service-main-title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  font-family: 'Syne', sans-serif;
  font-size: 5rem;
  letter-spacing: 15px;
  font-weight: bold;
  width: 100%;
  color: #264653;
  margin: 3rem 0rem 5rem;
}

.service-boxes-container {
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
}

.service-box {
  font-family: 'Syne', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  max-height: 60vh;
  width: 30%;
  padding: 45px;
  box-shadow: rgb(50 50 93 / 20%) 0px 30px 60px -12px,
    rgb(0 0 0 / 28%) 0px 18px 36px -18px;
  border-radius: 15px;
}

.illustration-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.illustration {
  transform: scale(1.8);
  margin: 50px 0px 80px 0px;
  width: 220px;
  height: 160px;
  position: relative;
  background-image: url('../../utilities/services-img.svg');
}

.spectrum-illustration p {
  color: #5d5d5d;
  line-height: 1.4em;
}

.illustration-web {
  background-position: 8px 8px;
}
.illustration-dev {
  background-position: 25px -179px;
}
.illustration-mobile {
  background-position: 0 -380px;
}

.blob {
  background: linear-gradient(
    to right,
    rgba(92, 129, 202, 1) 0%,
    rgba(249, 139, 112, 1) 100%
  );
  width: 16px;
  height: 16px;
  position: absolute;
  border-radius: 100%;
  transition: 0.25s;
  transform: scale(0);
}
#blob-1 {
  top: 1px;
  left: 72px;
}

#blob-2 {
  top: 22px;
  left: 40px;
}

#blob-3 {
  top: 104px;
  left: 18px;
}

.service-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 20vh;
  text-align: start;
}

.service-title {
  font-weight: 200;
  font-size: 1.8rem;
}

.service-description {
  opacity: 75%;
  font-size: 1.3rem;
}

/* ! Animations for illustrations:  */
@keyframes dash-1 {
  from {
    stroke-dashoffset: 385;
  }
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes dash-2 {
  from {
    stroke-dashoffset: 138;
  }
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes blob-1 {
  0% {
    transform: scale(1) translateX(0) translateY(0);
  }
  19% {
    transform: scale(1) translateX(-70px);
  }
  56% {
    transform: scale(1) translateX(-70px) translateY(141px);
  }
  75% {
    transform: scale(1) translateX(0) translateY(141px);
  }
  100% {
    transform: scale(1) translateX(70px) translateY(72px);
  }
}
@keyframes blob-2 {
  0% {
    transform: scale(1) translateX(0) translateY(0);
  }
  100% {
    transform: scale(1) translateX(141px) translateY(95px);
  }
}
@keyframes blob-3 {
  0% {
    transform: scale(1) translateX(0) translateY(0);
  }
  40% {
    transform: scale(1) translateX(33px) translateY(34px);
  }
  100% {
    transform: scale(1) translateX(120px) translateY(34px);
  }
}

/* * Hover effects entire service page @ min-width 1024px */
@media (min-width: 1024px) {
  .services-page:hover #line-web.animate {
    animation: dash-1 1.2s linear alternate forwards;
    animation-delay: 0.25s;
  }
  .services-page:hover #line-mobile.animate {
    animation: dash-2 0.7s ease alternate forwards;
    animation-delay: 0.25s;
  }

  .services-page:hover #line-dev.animate {
    animation: dash-2 0.9s linear alternate forwards;
    animation-delay: 0.25s;
  }

  .services-page:hover #blob-1.animate {
    transform: scale(1);
    animation: blob-1 1.2s linear alternate forwards;
    animation-delay: 0.25s;
  }

  .services-page:hover #blob-2.animate {
    transform: scale(1);
    animation: blob-2 0.9s ease alternate forwards;
    animation-delay: 0.25s;
  }

  .services-page:hover #blob-3.animate {
    transform: scale(1);
    animation: blob-3 0.9s linear alternate forwards;
    animation-delay: 0.25s;
  }
}

/* * Hover effect on individual service box at 1023px width */
@media (max-width: 1023px) {
  #sb1:hover #line-web.animate {
    animation: dash-1 1.2s linear alternate forwards;
    animation-delay: 0.25s;
  }
  #sb2:hover #line-mobile.animate {
    animation: dash-2 0.7s ease alternate forwards;
    animation-delay: 0.25s;
  }

  #sb3:hover #line-dev.animate {
    animation: dash-2 0.9s linear alternate forwards;
    animation-delay: 0.25s;
  }

  #sb1:hover #blob-1.animate {
    transform: scale(1);
    animation: blob-1 1.2s linear alternate forwards;
    animation-delay: 0.25s;
  }

  #sb2:hover #blob-2.animate {
    transform: scale(1);
    animation: blob-2 0.9s ease alternate forwards;
    animation-delay: 0.25s;
  }

  #sb3:hover #blob-3.animate {
    transform: scale(1);
    animation: blob-3 0.9s linear alternate forwards;
    animation-delay: 0.25s;
  }
}

/* *! Responsive Design */
@media (max-width: 1626px) and (min-width: 1416px) {
  .service-main-title {
    font-size: 70px;
    align-items: center;
    text-align: center;
    letter-spacing: 15px;
    margin: 1rem 0rem;
  }

  .service-box {
    max-height: 55vh;
  }

  .service-title {
    width: 100%;
    font-size: 20px;
    font-weight: 500;
  }

  .service-description {
    font-size: 18px;
  }

  .illustration {
    transform: scale(1.5);
  }
}

@media (max-width: 1415px) and (min-width: 1024px) {
  .services-page {
    padding: 50px 10px;
    width: fit-content;
    margin: 5vh 2vw;
    height: fit-content;
  }

  .service-boxes-container {
    display: flex;
    flex-direction: row;
    margin-top: 50px;
    justify-content: space-evenly;
    align-items: center;
    text-align: start;
    padding: 0px;
    width: 100%;
  }

  .service-main-title {
    font-size: 60px;
    padding: 0px 10px;
    align-items: center;
    letter-spacing: 15px;
    margin: 1rem 0rem;
  }

  .service-box {
    padding: 10px;
    width: 30%;
    height: 50vh;
    justify-content: space-evenly;
  }

  .service-title {
    font-weight: 500;
    width: 100%;
    font-size: 1.3rem;
  }

  .service-description {
    font-size: 1.2rem;
  }

  .service-text-container {
    display: flex;
    text-align: start;
    height: fit-content;
    padding: 60px 20px 20px 20px;
  }

  .illustration {
    transform: scale(1.2);
    margin: 20px 0px 15px 0px;
  }
}

@media (max-width: 1023px) and (min-width: 820px) {
  .services-page {
    padding: 50px 0px;
    margin: 5vh 5vw;
    align-items: center;
    justify-content: center;
    height: fit-content;
    overflow-y: scroll;
  }
  .service-main-title {
    font-size: 55px;
    align-items: center;
    text-align: center;
    padding-top: 20px;
    margin: 1rem 0rem;
  }

  .service-boxes-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
  }

  .service-box {
    height: fit-content;
    width: 90%;
    max-height: 70vh;
    margin: 100px 0px 50px 0px;
  }

  .service-text-container {
    width: 100%;
    height: fit-content;
    display: flex;
    text-align: start;
  }

  .service-title {
    font-size: 3rem;
    margin-top: 2rem;
  }

  .service-description {
    font-size: 1.8rem;
  }

  .illustration {
    transform: scale(2);
  }
}

@media (max-width: 819px) and (min-width: 431px) {
  .services-page {
    padding: 50px 20px;
    height: fit-content;
    align-items: center;
    justify-content: space-between;
    margin: 5vh 2vw;
  }

  .service-main-title {
    font-size: 5rem;
    margin-bottom: 5px;
    text-align: center;
    margin: 1rem 0rem 2rem 0rem;
  }

  .service-boxes-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }

  .service-box {
    width: 95%;
    max-height: 70vh;
    margin: 20px 0px 70px 0px;
  }

  .service-text-container {
    height: fit-content;
    text-align: start;
  }

  .service-title {
    margin: 25px 0px;
    text-align: start;
    font-size: 2.2rem;
  }

  .service-description {
    font-size: 1.5rem;
  }
  .illustration {
    transform: scale(2);
  }
}

@media (max-width: 430px) {
  .services-page {
    padding: 50px 0px;
    height: fit-content;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 5vh 2vw;
  }

  .service-main-title {
    font-size: 40px;
    letter-spacing: 15px;
    color: #264653;
    margin: 2rem 0rem 1rem;
  }

  .service-boxes-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }

  .service-box {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 90%;
    padding: 5px;
    height: fit-content;
    margin: 10px 0px;
    color: #264653;
  }

  .illustration {
    transform: scale(1);
    margin-bottom: 0px;
  }

  .service-text-container {
    height: fit-content;
  }

  .service-title {
    margin-top: 5px;
    font-size: 23px;
  }

  .service-description {
    font-size: 18px;
  }

  /* #sb1:hover #line-web.animate {
    animation: dash-1 1.2s linear alternate forwards;
    animation-delay: 0.25s;
  }
  #sb2:hover #line-mobile.animate {
    animation: dash-2 0.7s ease alternate forwards;
    animation-delay: 0.25s;
  }

  #sb3:hover #line-dev.animate {
    animation: dash-2 0.9s linear alternate forwards;
    animation-delay: 0.25s;
  }

  #sb1:hover #blob-1.animate {
    transform: scale(1);
    animation: blob-1 1.2s linear alternate forwards;
    animation-delay: 0.25s;
  }

  #sb2:hover #blob-2.animate {
    transform: scale(1);
    animation: blob-2 0.9s ease alternate forwards;
    animation-delay: 0.25s;
  }

  #sb3:hover #blob-3.animate {
    transform: scale(1);
    animation: blob-3 0.9s linear alternate forwards;
    animation-delay: 0.25s;
  } */
}
