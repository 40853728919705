.principle-item {
  font-family: 'Syne', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 300px;
  width: 95rem;
  margin: 40px 0vw 80px 0vw;
}

.principle-text-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.principle-header {
  font-size: 50px;
  letter-spacing: 3px;
}

.principle-desc {
  font-weight: 200;
  opacity: 80%;
  font-size: 40px;
  margin: 20px 0px 0px 0px;
}

@media (min-width: 1200px) {
}

@media (max-width: 1199px) and (min-width: 992px) {
  .principle-item {
    height: 280px;
    margin-bottom: 35px;
    width: 100%;
  }

  .principle-header {
    font-size: 35px;
  }

  .principle-desc {
    font-size: 25px;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .principle-item {
    width: 100%;
    padding: 0px;
  }
  .principle-header {
    font-size: 33px;
  }

  .principle-desc {
    font-size: 29px;
    width: 100%;
  }
}

@media (max-width: 767px) and (min-width: 647px) {
  .principle-item {
    width: 100%;
    padding: 0px;
  }

  .principle-header {
    font-size: 35px;
    letter-spacing: 3px;
  }

  .principle-desc {
    font-weight: 200;
    opacity: 80%;
    font-size: 20px;
    width: 65vw;
    margin: 0px 0px 0px 0px;
  }
}

@media (max-width: 646px) and (min-width: 400px) {
  .principle-item {
    height: 250px;
    width: 100%;
    padding: 0px;
  }

  .principle-header {
    font-size: 25px;
    letter-spacing: 1px;
  }

  .principle-desc {
    font-weight: 200;
    opacity: 80%;
    font-size: 18px;
    width: 100%;
    margin: 0px;
    padding: 0px;
  }

  /* .nextIcon{
    display: none;
  }

  .carousel-control-prev-icon {
    display: none;
  }
  .carousel-control-next-icon {
    display: none;
  } */
}

@media (max-width: 399px) {
  .principle-item {
    /* height: 380px; */
    height: 200px;
    width: 100%;
    padding: 0px;
  }
  .principle-header {
    font-size: 18px;
    letter-spacing: 1px;
    /* margin-bottom: 20px; */
  }

  .principle-desc {
    font-weight: 200;
    opacity: 80%;
    font-size: 15px;
    /* width: 60vw; */
    margin: 0px 0px 0px 0px;
  }
}
