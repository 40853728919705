.contact-form-container {
  width: fit-content;
  display: flex;
  height: fit-content;
  flex-direction: column;
  color: #ffddcc;
  font-family: "Syne", sans-serif;
}

.email-form-wrapper {
  display: flex;
  flex-direction: column;
  height: 30em;
  width: 20em;
  background-color: rgba(0, 0, 0, 0.896);
  border-radius: 25px;
  margin: 2vh;
  box-shadow: rgb(50 50 93 / 20%) 0px 30px 60px -12px,
    rgb(0 0 0 / 28%) 0px 18px 36px -18px;
  align-items: center;
}

.email-form {
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  justify-content: space-evenly;
}

.email-form h3 {
  margin: 25px;
  width: 100%;
}
.email-form input {
  margin-bottom: 2vh;
  width: 100%;
  border-radius: 10px;
  padding-left: 5px;
}

.email-form textarea {
  border-radius: 10px;
  padding-left: 5px;
}

#submit-button {
  align-self: flex-end;
  padding: 5px 10px;
  border-radius: 3px;
  outline: none;
  border: none;
  margin-top: 30px;
  width: 35%;
  font-family: "Syne", sans-serif;
}

#form-sent-status {
}

/* Responsive Design */

@media (min-width: 1200px) {
  .email-form-wrapper {
    min-height: 30vh;
    padding: 20px 85px 60px 85px;
  }
}

@media (max-width: 1199px) and (min-width: 992px) {
}

@media (max-width: 991px) and (min-width: 768px) {
}

@media (max-width: 767px) {
  .email-form-wrapper {
    height: 30vh;
    padding: 20px 45px 100px 45px;
    margin-left: 100px;
  }
}
