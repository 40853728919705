.projects-container {
  width: 100vw;
  font-family: 'Syne', sans-serif;
  margin: 5vh 0 14vh 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow-x: hidden;
}

.project-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: rgba(0, 0, 0, 0.4);
  color: #ffddcc;
  margin-bottom: 1vw;
  border-radius: 15px;
  width: 75vw;
  padding: 10px;
}

.img-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  border: hidden;
  border-radius: 15px;
}

.project-img {
  position: relative;
  width: 30vw;
  min-height: 30vh;
  padding: 1vh;
  transition: 1s all ease-in-out;
  border-radius: 25px;
}

.project-img:hover {
  transform: scale(1.2);
}

.project-details {
  display: flex;
  position: relative;
  justify-content: space-between;
  flex-direction: column;
  align-content: center;
  /* align-items: flex-start; */
  font-weight: lighter;
  text-align: start;
  width: 35vw;
  padding: 15px;
}

#project-title {
  font-size: 2.5rem;
  margin-bottom: 0;
}

#project-description {
  font-size: 1.3rem;
  padding-left: 0.5vw;
  padding-bottom: 3vh;
}

.contributors {
  font-size: 1rem;
  padding-bottom: 0.7vh;
}
.contributor {
  font-size: 0.9rem;
  padding: 5px;
  color: white;
  transition: 0.2s;
}

.contributor:hover {
  font-size: 0.95rem;
  color: rgba(249, 140, 112, 1);
}

/* Responsive Design */

@media (min-width: 1200px) {
}

@media (max-width: 1199px) and (min-width: 992px) {
  .project-container {
    width: 75vw;
    padding: 10px;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
}

@media (max-width: 800px) {
  .projects-container {
    margin-bottom: 5vh;
  }

  .project-container {
    flex-direction: column;
    align-items: center;
    margin-bottom: 3vh;
    padding-top: 4vh;
    width: 90%;
  }

  .project-img {
    width: 80vw;
  }

  .project-details {
    width: 70vw;
    padding-top: 1vh;
    text-align: start;
  }

  #project-title {
    font-size: 2.5rem;
    margin: .8vh 0vw;
    letter-spacing: 0.5rem;
  }

  #project-description {
    font-size: 1rem;
  }
}
